@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300&display=swap');

.Menu .Logo{
  font-family: 'Rubik', sans-serif;
}

.Menu .Red {
  color: rgb(204, 16, 16);
}
.Menu .Blue {
  color: rgb(17, 17, 218)
}
.Menu .Green{
  color: rgb(0, 128, 0);
}