@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Red {
  color: rgb(204, 16, 16);
}
.Blue {
  color: rgb(17, 17, 218)
}
.Green{
  color: rgb(0, 128, 0);
}

.App .center{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 600px;
}
.Menu .Logo{
  font-family: 'Rubik', sans-serif;
}

.Menu .Red {
  color: rgb(204, 16, 16);
}
.Menu .Blue {
  color: rgb(17, 17, 218)
}
.Menu .Green{
  color: rgb(0, 128, 0);
}
.Home {
  text-align: center;

}
.Home .Logo h1{
  font-family: 'Rubik', sans-serif;
  font-size: 40px;
}
  .Home .Logo h2{
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.Home .Red {
  color: rgb(204, 16, 16);
}
.Home .Blue {
  color: rgb(17, 17, 218)
}
.Home .Green{
  color: rgb(0, 128, 0);
}
