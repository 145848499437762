@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300&display=swap');

.Home {
  text-align: center;

}
.Home .Logo h1{
  font-family: 'Rubik', sans-serif;
  font-size: 40px;
}
  .Home .Logo h2{
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.Home .Red {
  color: rgb(204, 16, 16);
}
.Home .Blue {
  color: rgb(17, 17, 218)
}
.Home .Green{
  color: rgb(0, 128, 0);
}